import { createSlice } from "@reduxjs/toolkit";

const ServerInfoSlice = createSlice({
  name: "ServerInfo",
  initialState: {
    smartTools: [],
    loading: false,
  },
  reducers: {
    setServerInfo(state, action) {
      state.smartTools = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setServerInfo, setLoading } = ServerInfoSlice.actions;
export default ServerInfoSlice.reducer;
