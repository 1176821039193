import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideNotification } from "../../store/NotificationSlice";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const NotificationBox = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { message, type, show } = useSelector((state) => state.notification);

  const bgColor =
    type === "Error"
      ? "bg-[#EF4444]"
      : type === "Informal"
      ? "bg-[#ec942c]"
      : type === "Success"
      ? "bg-[#22C55E]"
      : "";

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => dispatch(hideNotification()), 5000);
      return () => clearTimeout(timer);
    }
  }, [show, dispatch]);

  useEffect(() => {
    dispatch(hideNotification());
  }, [location, dispatch]);

  if (!show) return null;

  return (
    <div
      className={`fixed top-0 right-0 transform translate-y-0 transition-transform duration-500 ${bgColor} z-30 top-10 text-white shadow-md p-4 rounded-lg m-4 max-w-sm w-full smallMobile:w-11/12 mobile:w-10/12`}
    >
      <div className="flex justify-between items-start">
        <span className="flex-1">{t(message)}</span>
        <button
          onClick={() => dispatch(hideNotification())}
          className=""
          text="&times;"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(NotificationBox);
