import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

const Users = React.lazy(() => import("../../pages/Users"));
const UserRoles = React.lazy(() => import("../../pages/UserRoles"));
const Subscription = React.lazy(() => import("../../pages/Subscription"));
const SmartTable = React.lazy(() => import("../../pages/SmartTable"));
const SmartInvoice = React.lazy(() => import("../../pages/SmartInvoice"));
const SmartMedicalLeaves = React.lazy(() =>
  import("../../pages/SmartMedicalLeaves")
);
const SmartCustoms = React.lazy(() => import("../../pages/SmartCustoms"));
const SmartICR = React.lazy(() => import("../../pages/SmartICR"));
const SmartPaperFlow = React.lazy(() => import("../../pages/SmartPaperFlow"));
const SmartReceipt = React.lazy(() => import("../../pages/SmartReceipt"));
const ApiPage = React.lazy(() => import("../../pages/ApiPage"));
const Loading = React.lazy(() => import("../../pages/Loading"));
const Welcome = React.lazy(() => import("../../pages/Welcome"));
const UserProfile = React.lazy(() => import("../../pages/UserProfile"));
const Register = React.lazy(() => import("../../pages/Register"));
const ServerInfo = React.lazy(() => import("../../pages/ServerInfo"));

const fallback = <Loading />;

const componentMapping = {
  "/Users": Users,
  "/userRoles": UserRoles,
  "/subscription": Subscription,
  "/smartTable": SmartTable,
  "/smartInvoice": SmartInvoice,
  "/smartMedical": SmartMedicalLeaves,
  "/smartCustoms": SmartCustoms,
  "/smartICR": SmartICR,
  "/smartPaperFlow": SmartPaperFlow,
  "/smartReceipt": SmartReceipt,
  "/apikeys": ApiPage,
  "/userprofile": UserProfile,
  "/serverinfo": ServerInfo,
};

const baseRoleToRoutes = {
  laigoAdmins: Object.keys(componentMapping),
  smartTable: ["/smartTable", "/userprofile"],
  smartInvoice: ["/smartInvoice", "/userprofile"],
  smartICR: ["/smartICR", "/userprofile"],
  smartCustoms: ["/smartCustoms", "/userprofile"],
  smartAPIs: ["/apikeys", "/userprofile"],
  smartPaperFlow: ["/smartPaperFlow", "/userprofile"],
  smartReceipt: ["/smartReceipt", "/userprofile"],
};

const roleToRoutes = Object.keys(baseRoleToRoutes).reduce((acc, role) => {
  acc[role] = [...baseRoleToRoutes[role], "/subscription"];
  return acc;
}, {});

const AuthenticatedRoutes = ({ userRoles }) => {
  const location = useLocation();
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [initialCheckComplete, setInitialCheckComplete] = useState(false);

  useEffect(() => {
    const routes = new Set();
    userRoles.forEach((role) => {
      roleToRoutes[role]?.forEach((route) => routes.add(route));
    });
    const sortedRoutes = Array.from(routes);
    if (sortedRoutes.includes("/smartTable")) {
      sortedRoutes.sort((a, b) =>
        a === "/smartTable" ? -1 : b === "/smartTable" ? 1 : 0
      );
    }
    setAllowedRoutes(sortedRoutes);
    setInitialCheckComplete(true);
  }, [userRoles]);

  if (!initialCheckComplete) {
    return fallback;
  }

  const isPathAllowed = allowedRoutes.includes(location.pathname);

  return (
    <Suspense fallback={fallback}>
      <Routes>
        {isPathAllowed ? (
          allowedRoutes.map((route) => (
            <Route
              key={route}
              path={route}
              element={React.createElement(componentMapping[route])}
            />
          ))
        ) : (
          <Route
            path="*"
            element={<Navigate replace to={allowedRoutes[0] || "/"} />}
          />
        )}
        <Route
          path="/"
          element={<Navigate replace to={allowedRoutes[0] || "/"} />}
        />
      </Routes>
    </Suspense>
  );
};

const RoutesContainer = ({ isAuth, resourceAccess }) => {
  if (!isAuth) {
    return (
      <Suspense fallback={fallback}>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/register/*" element={<Register />} />
          <Route path="*" element={<Welcome />} />
        </Routes>
      </Suspense>
    );
  }

  return <AuthenticatedRoutes userRoles={resourceAccess} />;
};

export default RoutesContainer;
