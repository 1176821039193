import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import "../../assets/css/components/UI/Button.scss";
import "@/assets/css/components/UI/Select.scss";
import "@/assets/css/components/UI/Button.scss";
import Select from "./Select";

function Button(props) {
  const { t } = useTranslation();
  const buttonText = t(props.text);
  const buttonRef = useRef(null);
  const [buttonPadding, setButtonPadding] = useState("10px 35px");

  const className = props.className || "";

  const wrapperClass = props.className.includes("newNeonButton")
    ? "button-wrapper"
    : "";

  useEffect(() => {
    const updatePadding = () => {
      const button = buttonRef.current;
      if (button) {
        if (button.offsetWidth > 160) {
          setButtonPadding("10px 25px");
        } else {
          setButtonPadding("10px 35px");
        }

        if (wrapperClass) {
          setButtonPadding("0px 35px");
        }
      }
    };

    updatePadding();

    window.addEventListener("resize", updatePadding);

    return () => window.removeEventListener("resize", updatePadding);
  }, [buttonText, wrapperClass]);

  return (
    <div className={wrapperClass} style={{ position: "relative" }}>
      <button
        ref={buttonRef}
        className={className}
        onClick={props.onClick}
        disabled={props.disabled}
        style={{ padding: buttonPadding }}
      >
        {buttonText}
      </button>
      {props.dropdown && (
        <div className="absolute top-full left-0 mt-2 w-full z-10">
          <Select
            selectedOption={props.selectedOption}
            options={props.options}
            onChange={props.onChange}
          />
        </div>
      )}
    </div>
  );
}

export default Button;
