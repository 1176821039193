const isProduction = process.env.VITE_NODE_ENV === "production";

const initOptions = {
url: "https://account.laigo.io", // the url of Keycloak server without /auth with new version of keycloak
realm: "SmartTools", // the name of the realm case sensitive
clientId: "laigo-api-key", // the name of the Client case sensitive
onLoad: "check-sso",
checkLoginIframe: true,
silentCheckSsoRedirectUri: "/src/assets/silent-check-sso.html",
};

// API - Backend IP-Adress
window.serverUrl = "https://use.laigo.io";

const stripePublishableKey = isProduction
? "pk_live_51P4I8MRubObAbCe9OyyHaB4w8IPbhtLCGLLr5x5H7uFEPvZf9FDQVMSBCPr6RNtBlCbaRuGpcgr07oVlU9kSHnGi00Y933UX0I" // Production Stripe publishable key
: "pk_test_51P4I8MRubObAbCe9N8stzJR7lFwtN09Itg1E0lfLEGO81HObDvhOSwHnmaztj4T7g4WTj0TaKTo8A6NO3BT4nqqC004fUk9Pax"; // Test Stripe publishable key

window.tokenExpireTime = 59; // IMPORTANT CHECK KEYCLOAK ADMIN CLIENT TOKEN EXPIRE TIME
// if the token expire set in keycloak is set to 1minute here should be half of it 1 = 60 / 2 = 30

window.testServer = false; // Please make sure set it to true if you are setting up in test server <----

export { initOptions, stripePublishableKey };
