import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from "react";
import "../../assets/css/components/UI/Select.scss";
import "../../assets/css/components/UI/Input.scss";
import { t } from "i18next";
import Tooltip from "./Tooltip";
import Input from "./Input";

const Select = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    if (props.multiSelect) {
      return props.selectedOption || props.value || [];
    } else {
      return props.selectedOption || props.value || "";
    }
  });
  const [showError, setShowError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleCloseOtherSelects = useCallback((event) => {
    if (event.detail !== wrapperRef.current) {
      setIsOpen(false);
      setIsActive(false);
    }
  }, []);

  const handleFocusOut = useCallback(
    (event) => {
      if (!wrapperRef.current.contains(event.relatedTarget)) {
        setIsOpen(false);
        setIsActive(false);
        if (props.required && !selectedOption) {
          setShowError(true);
        }
      }
    },
    [selectedOption, props.required]
  );

  useEffect(() => {
    const currentRef = wrapperRef.current;
    if (isOpen || isActive) {
      currentRef.addEventListener("focusout", handleFocusOut);
      window.addEventListener("closeOtherSelects", handleCloseOtherSelects);
    } else {
      currentRef.removeEventListener("focusout", handleFocusOut);
      window.removeEventListener("closeOtherSelects", handleCloseOtherSelects);
    }
    return () => {
      currentRef.removeEventListener("focusout", handleFocusOut);
      window.removeEventListener("closeOtherSelects", handleCloseOtherSelects);
    };
  }, [isOpen, isActive, handleFocusOut, handleCloseOtherSelects]);

  const toggleDropdown = () => {
    if (props.disabled) {
      return;
    }
    setIsOpen(!isOpen);
    setIsActive(true);
    if (!isOpen) {
      const event = new CustomEvent("closeOtherSelects", {
        detail: wrapperRef.current,
      });
      window.dispatchEvent(event);
    }
  };

  const handleOptionClick = (option) => {
    if (props.multiSelect) {
      let selections = [...selectedOption];
      const index = selections.indexOf(option);
      if (index > -1) {
        selections.splice(index, 1);
      } else {
        selections.push(option);
      }
      setSelectedOption(selections);
      props.onChange(selections);
    } else {
      setSelectedOption(option);
      props.onChange(option);
      setIsOpen(false);
      const event = new CustomEvent("closeOtherSelects", { detail: null });
      window.dispatchEvent(event);
    }
    setShowError(false);
    setIsActive(false);
  };

  const renderSelectedOptions = () => {
    if (
      !selectedOption ||
      (Array.isArray(selectedOption) && selectedOption.length === 0)
    ) {
      return props.placeholder || t("Select_option");
    }

    if (props.multiSelect) {
      return selectedOption.map((option) => t(option)).join(", ");
    } else {
      return t(selectedOption);
    }
  };

  useEffect(() => {
    setSelectedOption(props.selectedOption || props.value);
  }, [props.selectedOption, props.value]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = props.options.filter((option) =>
    t(option).toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className={`lg:w-full smallMobile:w-[90%] mwidth380 inputValue flex flex-col position-relative `}
      ref={wrapperRef}
      tabIndex={0}
    >
      <div className="flex flex-col">
        <div
          className={`${props.labelClass} font-normal text-gray-700 labelClass flex `}
        >
          {t(props.name)}
          {props.tooltip && !props.tooltipRight && (
            <Tooltip
              header={props.tooltipHeader}
              content={props.tooltipContent}
            />
          )}
        </div>
        <div className="relative flex">
          <div
            className={`customSelect ${
              props.disabled ? "selectDisabled" : ""
            } cursor-pointer sectionGrayBg2 rounded-md py-2 px-4 w-full text-left shadow-sm flex justify-between items-center ${
              isActive ? "focused" : ""
            } ${showError ? "errorSelect" : ""}`}
            onClick={toggleDropdown}
            ref={toggleButtonRef}
          >
            <span className="selected-options">{renderSelectedOptions()}</span>
            <span className="selectArrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
              >
                <path
                  d="M12.4213 1.68394L7.22954 7.22183C6.83446 7.64324 6.16554 7.64324 5.77046 7.22183L0.578695 1.68394C-0.0200546 1.04528 0.432792 0 1.30823 0H11.6918C12.5672 0 13.0201 1.04528 12.4213 1.68394Z"
                  fill="#D9D9D9"
                />
              </svg>
            </span>
          </div>
          <div
            className={`${props.labelClass} font-normal text-gray-700 labelClass flex pt-[10px]`}
          >
            {" "}
            {props.tooltip && props.tooltipRight && (
              <Tooltip
                header={props.tooltipHeader}
                content={props.tooltipContent}
                tooltipRight={true}
                iconRight={true}
              />
            )}
          </div>

          {isOpen && (
            <div className="customSelectOption absolute sectionGrayBg2 rounded-md mt-1 w-full shadow-lg z-10">
              {props.enableSearch && (
                <Input
                  type="text"
                  placeholder={t("Search")}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className=" searchInput  py-2 px-4 w-full border-none outline-none"
                />
              )}
              {Array.isArray(filteredOptions) ? (
                filteredOptions.map((option, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 flex items-center text-sm option hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleOptionClick(option)}
                  >
                    {props.multiSelect && (
                      <input
                        type="checkbox"
                        checked={selectedOption.includes(option)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleOptionClick(option);
                        }}
                        className="mr-2 styled-checkbox"
                      />
                    )}
                    {t(option)}
                  </div>
                ))
              ) : (
                <div
                  className="px-4 py-2 text-sm option hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleOptionClick(filteredOptions)}
                >
                  {t(filteredOptions)}
                </div>
              )}
            </div>
          )}

          {showError && (
            <div className="mandatoryField">
              {props.fieldError ? t("Field_Error") : t("Mandatory_Field")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Select;
