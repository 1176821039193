export const aggregateSmartCustoms = (modelsTimeUsage) => {
  let smartCustomsTotal = 0;
  let smartCustomsProcessingTime = 0;

  const aggregatedUsage = modelsTimeUsage.reduce((acc, item) => {
    const [key] = Object.keys(item).filter(k => k !== 'ProcessingTime');
    
    if (key.startsWith("smartCustoms_")) {
      smartCustomsTotal += Number(item[key]);
      if (item.ProcessingTime) {
        const minutes = parseInt(item.ProcessingTime) || 0;
        smartCustomsProcessingTime += minutes;
      }
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  if (smartCustomsTotal > 0) {
    aggregatedUsage.push({
      smartCustoms: smartCustomsTotal,
      ProcessingTime: `${smartCustomsProcessingTime}m`
    });
  }

  return aggregatedUsage;
};
