import icon_smartcustoms from "../assets/icons/icon_smartcustoms.svg";
import icon_smartdocsplit from "../assets/icons/icon_smartpaperflow.svg";
import icon_smartreceipt from "../assets/icons/icon_smartreceipt.svg";
import icon_smarticr from "../assets/icons/icon_smarticr.svg";
import icon_smartinvoice from "../assets/icons/icon_smartinvoice.svg";
import icon_smartmedical from "../assets/icons/icon_smartinvoice.svg";
import icon_smarttable from "../assets/icons/icon_smarttable.svg";
import subscriptionIcon from "../assets/icons/icon_subscription.svg";
import UsersSvg from "../assets/icons/icon_administration.svg";

export const menuConfig = [
  {
    name: "Text_Extraction",
    link: "/smartICR",
    icon: icon_smarticr,
    roles: ["smartICR"],
  },
  {
    name: "Invoice_Extraction",
    link: "/smartInvoice",
    icon: icon_smartinvoice,
    roles: ["smartInvoice"],
  },
  {
    name: "smartCustoms",
    link: "/smartCustoms",
    icon: icon_smartcustoms,
    roles: ["smartCustoms"],
  },
  {
    name: "Table_Extraction",
    link: "/smartTable",
    icon: icon_smarttable,
    roles: ["smartTable"],
  },
  {
    name: "smartPaperFlow",
    link: "/smartPaperFlow",
    icon: icon_smartdocsplit,
    roles: ["smartPaperFlow"],
  },
  {
    name: "smartReceipt",
    link: "/smartReceipt",
    icon: icon_smartreceipt,
    roles: ["smartReceipt"],
  },
  {
    name: "smart_medical_leave",
    link: "/smartMedical",
    icon: icon_smartmedical,
    roles: ["smartMedicalLeaves"],
  },
  {
    name: "Menu_Administration",
    icon: UsersSvg,
    isDropdownTrigger: true,
    dropdownId: "adminDropdown",
    roles: ["laigoAdmins", "smartAPIs"],
    subItems: [
      {
        name: "Menu_ApiKeys",
        link: "/apikeys",
        // icon: apikeys,
        roles: ["laigoAdmins", "smartAPIs"],
      },
      {
        name: "Menu_Mgmtdashboard",
        link: "/Users",
        // icon: UsersSvg,
        roles: ["laigoAdmins"],
      },
      {
        name: "Roles",
        link: "/userRoles",
        // icon: UsersSvg,
        roles: ["laigoAdmins"],
      },
      {
        name: "ServerInfo",
        link: "/serverinfo",
        // icon: UsersSvg,
        roles: ["laigoAdmins"],
      },
    ],
  },
  {
    name: "Menu_subscription",
    link: "/subscription",
    icon: subscriptionIcon,
    roles: [
      "laigoAdmins",
      "subscription",
      "smartAPIs",
      "smartICR",
      "smartInvoice",
      "smartTable",
      "smartPaperFlow",
      "smartCustoms",
      "smartReceipt",
      "smartMedicalLeaves",
    ],
  },
];
