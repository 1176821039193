import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { t } from "i18next";
import { setSidebarOpen } from "../../store/DefaultSettings";
import { menuConfig } from "../../helpers/menuItems";

function MobileMenu() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuth = useSelector((state) => state.authStatus.isLoggedIn);
  const resourceAccess = useSelector(
    (state) => state.authStatus.resourceAccess
  );
  const sidebarOpen = useSelector((state) => state.defaultSettings.sidebarOpen);

  const [menuOpen, setMenuOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setAdminOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const isAdminRoute = menuConfig.find(
      (item) =>
        item.isDropdownTrigger &&
        item.subItems &&
        item.subItems.some((subItem) => subItem.link === location.pathname)
    );

    if (isAdminRoute) {
      setAdminOpen(true);
    } else {
      setAdminOpen(false);
    }
  }, [location]);

  const handleMenuIconClick = () => {
    setMenuOpen(!menuOpen);
    if (!sidebarOpen) {
      dispatch(setSidebarOpen(true));
    }
  };

  const toggleAdminDropdown = (e) => {
    e.stopPropagation();
    setAdminOpen(!adminOpen);
  };

  const hasAccessToMenu = (menuRoles) => {
    if (resourceAccess.includes("laigoAdmins")) {
      return true;
    }
    return menuRoles.some((role) => resourceAccess.includes(role));
  };

  const isSelected = (path) => location.pathname === path;

  const renderMenuItem = (item, index, isSubItem = false) => {
    if (!isAuth || !hasAccessToMenu(item.roles)) {
      return null;
    }

    const isItemSelected = isSelected(item.link);

    return (
      <li
        key={index}
        className={`py-3 flex overflow-auto max-h-min ${
          isSubItem ? "pl-4" : ""
        }`}
      >
        {item.icon && (
          <img
            src={item.icon}
            alt={item.name}
            className={`mt-1 ${isSubItem ? "mr-2" : "mx-2"} w-8 h-8 `}
          />
        )}
        <Link
          to={item.link}
          className={`align-middle self-center ${
            isItemSelected ? "text-[#68a3c0]" : "text-white "
          } ${isItemSelected ? "font-bold" : ""}`}
          onClick={() => setMenuOpen(false)}
        >
          {t(item.name)}
        </Link>
      </li>
    );
  };

  return (
    <nav
      ref={menuRef}
      className={`${
        !menuOpen ? "" : "bg-[#0e223a]"
      } lg:mt-12 text-white lg:w-screen p-4 md:hidden lg:hidden`}
    >
      <HiMenuAlt3
        size={26}
        className="cursor-pointer ml-auto"
        onClick={handleMenuIconClick}
      />

      <ul
        className={`${
          !menuOpen ? "hidden" : ""
        } duration-500 absolute w-64 right-0 bg-[#0e223a] transition-all max-h-[calc(85vh-6rem)] overflow-y-auto`}
      >
        {menuConfig.map((item, index) => {
          if (item.isDropdownTrigger && hasAccessToMenu(item.roles)) {
            return (
              <React.Fragment key={index}>
                <li
                  className="py-3 flex cursor-pointer "
                  onClick={toggleAdminDropdown}
                >
                  <img src={item.icon} alt="" className="mt-1 mx-2 w-8 h-8" />
                  <span
                    className={`align-middle self-center ${
                      adminOpen ? "text-[#68a3c0]" : "text-white"
                    }`}
                  >
                    {t(item.name)}
                  </span>
                </li>
                {adminOpen && item.subItems && (
                  <ul className={"flex flex-col pl-10"}>
                    {item.subItems.map((subItem, subIndex) =>
                      renderMenuItem(subItem, `${index}-${subIndex}`, true)
                    )}
                  </ul>
                )}
              </React.Fragment>
            );
          } else {
            return renderMenuItem(item, index);
          }
        })}
      </ul>
    </nav>
  );
}

export default MobileMenu;
