import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadState: 1,
  resultsForDownload: null,
  resultsReceived: false,
  uploadedFileName: "",
  medicalLeaveData: null,
  ieJobId: null,
};

const smartMedicalLeaveSlice = createSlice({
  name: "smartMedicalLeaves",
  initialState,
  reducers: {
    setUploadStateSmartMedicalLeave: (state, action) => {
      state.uploadState = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setSmartMedicalLeaveData: (state, action) => {
      state.medicalLeaveData = action.payload;
    },
    setJobIdSmartMedicalLeave: (state, action) => {
      state.ieJobId = action.payload;
    },
  },
});

export const {
  setUploadStateSmartMedicalLeave,
  setResultsForDownload,
  setResultsReceived,
  setUploadedFileName,
  setSmartMedicalLeaveData,
  setJobIdSmartMedicalLeave,
} = smartMedicalLeaveSlice.actions;

export default smartMedicalLeaveSlice.reducer;
